import React from 'react';

import { FormattedMessage } from 'react-intl';
import { NotificationManager } from 'react-notifications';

import { Button, Spinner } from 'jpi-cloud-web-ui-components';

import { history } from '../../../../../../store/configureStore';

import Spacer from '../../../../../layout/Spacer';

const UnsupportedCountryError = new Error('Unsupported Country');
UnsupportedCountryError.name = 'UnsupportedCountryError';
UnsupportedCountryError.cause = 'unsupportedCountry';

const SubscriptionError = ({ errorData }) => {
  try {
    const serverError = JSON.parse(errorData?.internal_error);

    if (serverError?.error === UnsupportedCountryError.cause)
      return (
        <>
          <h4 className={'Product__error'}>
            <FormattedMessage
              id="shop.page.subscriptionsError"
              defaultMessage="An error occurred while loading subscriptions."
            />
          </h4>
          <p>
            <FormattedMessage
              id="shop.page.askToUpdateAddress"
              defaultMessage="Please go to profile settings and update your address information to enable subscriptions."
            />
          </p>
          <Spacer y={30} />
          <Button className="button--primary" onClick={() => history.push('/profile-settings')}>
            Profile Settings
          </Button>
        </>
      );
  } catch (error) {
    NotificationManager.error(
      <FormattedMessage id="generic.error.request.unknown" defaultMessage="An error has occurred. Try again later." />,
    );

    return <Spinner dark />;
  }
};

export default SubscriptionError;
