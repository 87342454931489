import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import { FormattedMessage } from 'react-intl';

import { Spinner, Title } from 'jpi-cloud-web-ui-components';

import AddDeviceForm from './components/AddDeviceForm';
import ValidateTokenForm from './components/ValidateTokenForm';

import { addDevice, validateToken, clearToken, clearErrorMessage } from './actions';
import { setLastUsedSystem } from '../../layout/SystemSelector/actions';
import { getUserSystems, selectSystem } from '../../AppInitializer/actions';
import { getDevices, getSubscriptionsInGroup } from '../Devices/actions';
import { getFeatures } from '../../FeaturesFlags/actions';

import './add-device.scss';

class AddDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isTokenValid: false,
      suggestedInfo: {},
      serialNumber: null,
      connectionString: null,
      newSystemId: null,
      error: null,
    };
  }

  componentWillUnmount() {
    this.props.clearErrorMessage();
  }

  addDevice = async values => {
    const { addDevice, serialNumber, connectionString } = this.props;

    const newAddress = {
      city: values.address.city.trim(),
      country: values.address.country,
      lineOne: values.address.lineOne.trim(),
      lineTwo: values.address.lineTwo.trim(),
      postalCode: values.address.postalCode.trim(),
      region: values.address.region.trim(),
    };

    try {
      await addDevice({
        serialNumber,
        token: connectionString,
        groupName: values.groupName.trim(),
        groupId: values.system.id.trim(),
        address: newAddress,
      });
    } catch (error) {
      if (error?.response?.status === 400) {
        const { description } = error?.response?.data || {};

        if (description === 'Invalid Address')
          return NotificationManager.error(
            <FormattedMessage
              id="add-device.form.error.address"
              defaultMessage="Unable to add device: Address is not valid."
            />,
          );
      }
    }
  };

  goToTokenValidation = () => {
    this.props.clearToken();
  };

  validateToken = async values => {
    const newValues = {
      serialNumber: values.serialNumber.trim(),
      connectionString: values.connectionString.trim(),
    };

    await this.props.validateToken(newValues.serialNumber, newValues.connectionString, this.props.userInfo.id);
  };

  onCancel = () => {
    this.props.history.push('/');
  };

  static async getDerivedStateFromProps(props, state) {
    if (props.isRedirectToGroupAction) {
      await props.clearToken();
      const system = await props.getUserSystems(props.redirectGroupId);
      props.getSubscriptionsInGroup(system.id);
      props.setLastUsedSystem(system.id, system.brandId);
      const devices = await props.getDevices(props.redirectGroupId);
      if (devices && devices.length > 0) {
        await getFeatures(devices[0].id);
      }
      await props.history.push('/devices');
    }
    return { ...state };
  }

  async componentDidUpdate(prevProps) {
    const {
      getUserSystems,
      getDevices,
      getFeatures,
      history,
      newSystemId,
      setLastUsedSystem,
      getSubscriptionsInGroup,
    } = this.props;

    if (newSystemId && newSystemId !== prevProps.newSystemId) {
      const system = await getUserSystems(newSystemId);
      setLastUsedSystem(system.id, system.brandId);
      getSubscriptionsInGroup(system.id);
      const devices = await getDevices(system.id);
      if (devices && devices.length > 0) {
        await getFeatures(devices[0].id);
      }
      history.push('/');
    }
  }

  render() {
    const { systems, countries, isTokenValid, suggestedInfo, loading, submitting, error, userInfo } = this.props;
    const isDemoUser = userInfo && userInfo.isDemo;

    return (
      <div className="page-content">
        <Title titleTranslationId="addDevice.heading" defaultMessage="Add new device" />
        {loading && <Spinner dark />}

        {isTokenValid == false && loading === false && (
          <ValidateTokenForm
            onSubmit={this.validateToken}
            error={error}
            loading={loading}
            onCancel={this.onCancel}
            isDemoUser={isDemoUser}
          />
        )}

        {isTokenValid && (
          <AddDeviceForm
            systems={systems}
            countries={countries}
            suggestedAddress={suggestedInfo && suggestedInfo.address}
            onSubmit={this.addDevice}
            suggestedInfo={suggestedInfo}
            goToPrevStep={this.goToTokenValidation}
            loading={submitting}
          />
        )}
      </div>
    );
  }
}

AddDevice.propTypes = {
  systems: PropTypes.array.isRequired,
  userInfo: PropTypes.object,
  history: PropTypes.object,
  addDevice: PropTypes.func.isRequired,
  isTokenValid: PropTypes.bool.isRequired,
  serialNumber: PropTypes.string,
  connectionString: PropTypes.string,
  validateToken: PropTypes.func.isRequired,
  clearToken: PropTypes.func.isRequired,
  clearErrorMessage: PropTypes.func.isRequired,
  suggestedInfo: PropTypes.object,
  getUserSystems: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  selectSystem: PropTypes.func.isRequired,
  getDevices: PropTypes.func.isRequired,
  isRedirectToGroupAction: PropTypes.bool,
  redirectGroupId: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.object),
  newSystemId: PropTypes.string,
  getFeatures: PropTypes.func.isRequired,
  setLastUsedSystem: PropTypes.func.isRequired,
  getSubscriptionsInGroup: PropTypes.func.isRequired,
};

export default connect(
  ({
    app: { systems, userInfo, countries },
    addDevice: {
      loading,
      isTokenValid,
      suggestedInfo,
      error,
      submitting,
      redirectGroupId,
      isRedirectToGroupAction,
      serialNumber,
      connectionString,
      newSystemId,
    },
  }) => ({
    systems,
    userInfo,
    countries,
    isTokenValid,
    suggestedInfo,
    loading,
    submitting,
    error,
    redirectGroupId,
    isRedirectToGroupAction,
    serialNumber,
    connectionString,
    newSystemId,
  }),
  {
    addDevice,
    validateToken,
    clearToken,
    getUserSystems,
    selectSystem,
    getDevices,
    clearErrorMessage,
    getFeatures,
    setLastUsedSystem,
    getSubscriptionsInGroup,
  },
)(AddDevice);
